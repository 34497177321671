<template>
  <div>
    <Header />
 <br /><br /><br /><br /><br /><br /><br />
    <div class="container fluid">
      <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="temperature()">
            <div class="card" style="width: 260px; height: 180px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="../../../assets/images/subcategorie/inspection_testing/heat_cold_testing/temperature-testing-chambers-ur-332.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">Temperature Testing Chambers </h4>
              </div>
            </div>
          </a>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="temperatureandhum()">
            <div class="card" style="width: 260px; height: 180px">
              <img
                style="max-height: 80%; max-width: 50%; margin: auto"
                class="card-img-top img-fluid"
                src="../../../assets/images/subcategorie/inspection_testing/heat_cold_testing/temperature-humidity-chamber.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">Temperature & Humidity Chambers </h4>
              </div>
            </div>
          </a>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="vacumdrying()">
            <div class="card" style="width: 260px; height: 180px">
              <img
                style="max-height: 80%; max-width: 60%; margin: auto"
                class="card-img-top img-fluid"
                src="../../../assets/images/subcategorie/inspection_testing/heat_cold_testing/vacuum-dry-ovens.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">Vacuum Drying Ovens</h4>
              </div>
            </div>
          </a>
        </div>
        <!-- end col -->
        
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../components/Header.vue";

import Footer from "../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
  methods: {
     temperature(){
      window.location.href = "https://www.sinerji-grup.com/temperature-testing-chambers-ur-332";

    },
    temperatureandhum(){
      window.location.href = "https://www.sinerji-grup.com/temperature-humidity-chambers";

    },
    vacumdrying(){
      window.location.href = "https://www.sinerji-grup.com/vacuum-drying-ovens";
 
    },
  },
};
</script>